import { configureStore } from "@reduxjs/toolkit";
import apiSlice from "../services/ApiSlice";
import AuthSlice from "./slices/AuthSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { fileExplorerSlice } from "../services/FileExplorer";
import ApplicationSlice from "./slices/ApplicationSlice";
import TravelerSlice from "./slices/TravelerSlice";

const store = configureStore({
  reducer: {
    auth: AuthSlice,
    application : ApplicationSlice ,
    traveler : TravelerSlice ,

    [apiSlice.reducerPath]: apiSlice.reducer,
    [fileExplorerSlice.reducerPath]: fileExplorerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([apiSlice.middleware, fileExplorerSlice.middleware]),
});

setupListeners(store.dispatch);
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
