import { createSlice, Slice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type userData = {
  mobile: string;
  name: string;
  userId: string;
  email: string;
  userName: string;
};

export interface AuthSLiceStateType {
  accessToken: string | null;
  refreshToken: string | null;
  userAccess: string | null;
  userData: userData | null | any;
  userType: string | null;
  isLogin: boolean;
  webInformation? : any
}

const initialState: AuthSLiceStateType = {
  accessToken: localStorage.getItem("accessToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  userAccess: null,
  userData:localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData") || '')  : null,
  isLogin: localStorage.getItem("isLogin") === "true" ? true : false,
  userType: "ADMIN",
  webInformation : {}
};

const authSlice: Slice<AuthSLiceStateType> = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string | null>) => {
      state.refreshToken = action.payload;
    },

    resetState: () => {
      return initialState;
    },
    setUserAccess: (state, action: PayloadAction<string | null>) => {
      state.userAccess = action.payload;
    },
    setUserData: (state, action: PayloadAction<userData | null>) => {
      state.userData = action.payload;
    },
    setUserType: (state, action: PayloadAction<string | null>) => {
      state.userType = action.payload;
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setWebInformation: (state, action: PayloadAction<any>) => {
      state.webInformation = action.payload;
    },
  },
});

export const {
  setAccessToken,
  setRefreshToken,
  resetState,
  setUserAccess,
  setUserData,
  setUserType,
  setIsLogin,
  setWebInformation
} = authSlice.actions;
export default authSlice.reducer;
