import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../redux/store";
import { BASE_URL } from "../utils/constants";
import { authTokenKeyName } from "../utils/configs/authConfig";

const  tagTypes =['visa' , 'traveler']

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,

    prepareHeaders: (headers:any, { getState, endpoint }:any) => {
      const token =
        (getState() as RootState)?.auth?.accessToken ||
        localStorage.getItem(authTokenKeyName);
      if (token) {
        headers.set("x-access-token", token);
      }
      return headers;
    },
  }),
  tagTypes: tagTypes,
  endpoints: () => ({}),
});

export default apiSlice;
