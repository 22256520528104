
import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
const HomePage = React.lazy(() => import("./screens/HomePage/HomePage"));
const MOLChooseVisaCard = React.lazy(
  () => import("./components/UI/molecules/MOLChooseVisaCard/MOLChooseVisaCard")
);
const VisaDurationDaysWrapper = React.lazy(
  () => import("./screens/VisaDurationDays/VisaDurationDaysWrapper")
);
const Layout = React.lazy(() => import("./components/layouts"));
const VisaApplicationFormWrapper = React.lazy(
  () => import("./screens/VisaApplicationForm/VisaApplicationFormWrapper")
);

const ProfileLayouts = React.lazy(
  () => import("./screens/MyProfile/ProfileLayouts")
);

const AddTravelerFormWrapper = React.lazy(
  () => import("./screens/MyProfile/Traveler/Add/AddTravelerFormWrapper")
);
const TermsConditions = React.lazy(
  () => import("./screens/TermsConditions/TermsConditions")
);

const HowToApply = React.lazy(
  () => import("./screens/HowToApplyPage/HowToApplyPage")
);
const FaqPage = React.lazy(() => import("./screens/FaqPage/FaqPage"));
const ContactUsPage = React.lazy(
  () => import("./screens/ContactUs/ContactUsPage")
);
const ViewApplicationWrapper = React.lazy(
  () => import("./screens/MyProfile/MyApplications/View/ViewApplicationWrapper")
);
const ViewTravelerDetailsWrapper = React.lazy(
  () => import("./screens/MyProfile/Traveler/View/ViewTravelerDetailsWrapper")
);
const EditTravelerDetailsWrapper = React.lazy(
  () => import("./screens/MyProfile/Traveler/Edit/EditTravelerDetailsWrapper")
);
const SuccessPage = React.lazy(
  () => import("./screens/SuccessPage/SuccessPage")
);
const FailedPage = React.lazy(() => import("./screens/FailedPage/FailedPage"));

const PageRoutes = () => {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        { path: "/", element: <HomePage /> },
        { path: "/faq", element: <FaqPage /> },
        { path: "/contact-us", element: <ContactUsPage /> },
        { path: "/how-to-apply", element: <HowToApply /> },
        { path: "/application", element: <VisaApplicationFormWrapper /> },
        { path: "/profile", element: <ProfileLayouts /> },
        { path: "/traveler-add", element: <AddTravelerFormWrapper /> },
        { path: "/terms-conditions", element: <TermsConditions /> },
        { path: "/uae-visa", element: <MOLChooseVisaCard /> },
        {
          path: "/profile/:applicationId",
          element: <ViewApplicationWrapper />,
        },
        {
          path: "/profile/view-traveler/:travelerId",
          element: <ViewTravelerDetailsWrapper />,
        },
        {
          path: "/profile/edit/:travelerId",
          element: <EditTravelerDetailsWrapper />,
        },
        {
          path: "success",
          element: <SuccessPage />,
        },
        {
          path: "cancel",
          element: <FailedPage />,
        },
        {
          path: "visas",
          element: <VisaDurationDaysWrapper />,
        },
      ],
    },
  ]);
  return (
    <Suspense
      fallback={
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <div className="loader">

          </div>
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default PageRoutes;
